@import "variables";
@import "flex";
@import "icons";
@import "cards";

.root-container {
	height: 100%;
	width: 100%;
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	background-color: $main-primary-color;
}

.input-container {
	margin-bottom: 1rem;
	width: 100%;
}

// Premium list items
@mixin options-dropdown-list {
	@extend %smash-card-menu;
	transition: opacity 2s ease-in;
	text-transform: none;

	>li {
		padding: 2px 10px;
		color: $grey-dark;
		line-height: 20px;

		a {
			display: block;
		}

		span {
			opacity: 0.7;
		}

		&:hover {
			color: $black;
			cursor: pointer;
			opacity: 1;
		}

		.menu-dropdown-item-container {
			position: relative;
			@include flexbox;
			@include align-items(center);

			label {
				position: relative;
				min-height: 2.8rem;
				display: block;
				padding-left: 3rem;
				margin-bottom: 0;
				font-weight: normal;
				cursor: pointer;

				span {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}

				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 2rem;
					height: 2rem;
					transition: transform 0.28s ease;
					border-radius: 3px;
					border: 1px solid $main-medium-color;
				}

				&:after {
					content: "";
					display: block;
					width: 10px;
					height: 5px;
					border-bottom: 2px solid $main-light-color;
					border-left: 2px solid $main-light-color;
					transform: rotate(-45deg) scale(0);
					transition: transform ease 0.25s;
					position: absolute;
					top: 7px;
					left: 5px;
				}
			}

			input[type="checkbox"] {
				width: auto;
				opacity: 0.00000001;
				position: absolute;
				left: 0;
				margin-left: -20px;

				&:checked~label {
					&:before {
						background: $main-primary-color;
						border: 0px solid $main-primary-color;
					}

					&:after {
						transform: rotate(-45deg) scale(1);
					}
				}

				&:focus+label::before {
					outline: 0;
				}
			}
		}
	}

	::ng-deep {
		li {
			padding: 5px 10px;
			font-size: 1.2rem;
			color: $grey-dark;
			line-height: 20px;

			a {
				display: block;
			}

			span {
				opacity: 0.7;
			}

			&:hover {
				color: $black;
				opacity: 1;
				cursor: pointer;
			}
		}
	}
}

.mobile-list {
	overflow: auto;
	background: $light-bg-color;
	border-radius: 1.3rem;
	overflow: scroll;
	height: calc(100vh - 22rem);
	margin-bottom: 2rem;

	&.no-scroll {
		max-height: none;
		height: unset;
	}

	li {
		@include flexbox;
		@include justify-content(center);
		@include align-items(center);
		color: $grey-dark;
		line-height: 3.2rem;
		overflow: hidden;
		border-bottom: 1px solid $grey-light;
		width: 100%;
		padding: 1rem 2rem;
		min-height: 7.6rem;

		&:not(:first-child) {
			margin-top: -1px;
		}

		&:last-child {
			border: none;
		}

		.fake-loading {
			height: 3rem;
			color: transparent;
			background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
			background-size: 400%;
			animation: loading 1.2s ease-in-out infinite;
			width: 100%;
		}

		.meta-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding-bottom: 0.3rem;
			line-height: 2.6rem;

			.title {
				@extend %flex-layout-row;
				@include justify-content(space-between);
				@include align-items(center);
				width: 100%;

				.title-name {
					margin-left: 0.5rem;
					color: $grey-dark;
					font-weight: 900;
					font-size: 1.6rem;
					width: 100%;
					flex: 1;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.date {
					color: $grey-dark;
					font-size: 1.6rem;
					width: fit-content;
					white-space: nowrap;
					display: inline-block;
				}
			}

			.description {
				@include flexbox;
				@include flex-direction(row);
				padding-left: 0.5rem;
				color: $grey;
				font-weight: 400;
				font-size: 1.5rem;

				span:not(:first-child) {
					padding-left: 0.4rem;
				}
			}
		}
	}

	.no-content {
		@extend %flex-layout-column;
		@include justify-content(center);
		@include align-items(center);
		min-height: 15rem;
		height: 100%;
		text-align: center;
		padding: 0 1rem;

		.no-content-message {
			font-size: 1.5rem;
		}
	}
}

@keyframes loading {
	0% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0 50%;
	}
}