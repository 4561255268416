@import 'variables';

.chip {
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 0.8rem;
    display: inline-block;
    color: #fff;
    width: auto;
    margin: 0 1rem;
    width: fit-content;
    min-width: fit-content;
    height: fit-content;
    
    &.valid {
      background-color: $green-bg-color;
    }
  
    &.danger {
      background-color: $red;
    }

    &.default {
      background-color: $main-medium-color;
    }
  }