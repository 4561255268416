@import "variables";

@mixin size($width, $height: $width) {
  @if _is-size($height) {
    height: $height;
  }

  @else {
    @error "`#{$height}` is not a valid length for the `$height` argument "+"in the `size` mixin.";
  }

  @if _is-size($width) {
    width: $width;
  }

  @else {
    @error "`#{$width}` is not a valid length for the `$width` argument "+"in the `size` mixin.";
  }
}

// Breakpoint height
@mixin lg-height {
  @media (max-height: #{$break-height-lg}) {
    @content;
  }
}

@mixin md-height {
  @media (max-height: #{$break-height-md}) {
    @content;
  }
}

@mixin sm-height {
  @media (max-height: #{$break-height-sm}) {
    @content;
  }
}

@mixin xs-height {
  @media (max-height: #{$break-height-xs}) {
    @content;
  }
}

// Very very small devices
@mixin xxs {
  @media (max-width: #{$break-xxs}) {
    @content;
  }
}

// Very small devices
@mixin xs {
  @media (max-width: #{$break-xs}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$break-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$break-md}) {
    @content;
  }
}

@mixin mdlg {
  @media (max-width: #{$break-mdlg}) {
    @content;
  }
}

@mixin max-width-sender {
  @media (max-width: #{$breakpoint-sender}) {
    @content;
  }
}

// Large large devices
@mixin lg {
  @media (max-width: #{$break-lg}) {
    @content;
  }
}

// Mixin cover vimeo
@mixin sizemin-cover-vimeo {
  @media (max-width: #{$breakpoint-cover-vimeo-width}) and (max-height: #{$breakpoint-cover-vimeo-height}) {
    @content;
  }
}

// Mobile

@mixin mobile-xs {
  @media (max-width: #{$break-mobile-width-xs}) and (max-height: #{$break-mobile-height-xs}) {
    @content;
  }
}