// 
// Colors //
//

// Main colors
$main-primary-color: #EA5159;
$main-dark-color: #000;
$main-light-color: #fff;
$main-medium-color: #7F7F7F;
$main-medium-color-ligthen: lighten($main-medium-color, 25%);

// Secondary colors
$secondary-color01: #CCFF66;
$secondary-color02: #66CCCC;
$secondary-color03: #58EABB;
$secondary-color04: #F6D2E9;

// background colors
$primary-bg-color: #EA5159;
$light-bg-color: #fff;
$medium-bg-color: #F7F9FA;
$blue-bg-color: #4036a6;
$light-grey-bg-color: #e8e8e8;
$green-bg-color: #10A375;

// Grey Colors
$premium-grey-border-color: #D6DBE0;

$bg-white: #fafafa;
$black: #000;
$grey: #888;
$grey-line: #f1f1f1;
$grey-dark: #333;
$grey-medium: #7F7F7F;
$grey-medium-light: #949494;
$grey-light: #ccc;
$grey-lighter: #F2F2F2;
$overlay-modal-background-color: rgba(0, 0, 0, 0.8);
$smash-blue: #4285F4;
$smash-blue-light: #B0C4DE;
$red: #ea5159;
$overlay-background : rgba(0, 0, 0, 0.8);
$warning: orange;
$black-light: #3a3a3a;
$gold: #f1cb36;

// readonly input copy / paste
$readonly-color: #777;
$readonly-background: #eee;
$readonly-border: solid 1px #ddd;

// Promotion teaser background
$promotion-teaser-background: #3a3a3a; //#d7d3d9;

// files category colors
$file-audio-background: #F2AA84;
$file-video-background: #F2E861;
$file-image-background: #61CBF4;
$file-document-background: #00E5A3;
$file-unknown-background: $grey-dark;

$file-audio-color: $grey-dark;
$file-video-color: $grey-dark;
$file-image-color: $grey-dark;
$file-document-color: $grey-dark;
$file-unknown-color: $main-light-color;

//Premium colors
$premium-side-top-bar-background-color: $bg-white;
$premium-router-background-color: $main-light-color;
// premium layout
$premium-layout-max-width : 130rem;

//font-size-premium
$tabs-font-size: 1.5rem;
$section-title-font-size : 2rem;
$card-title-font-size : 1.5rem;
$card-content-font-size : 0.6rem;
$card-item-title-font-size: 1.5rem;
$card-item-description-font-size: 1.4rem;
$menu-label-font-size: 1.75rem;
$menu-options-font-size : 0.75rem;
$error-info-font-size: 1rem;

//font-weight-premium
$tabs-font-weight: 600;
$card-title-font-weigth : 900;
$card-item-title-font-weight: 900;

//color-font-premium
$tabs-font-color: $grey-medium;
$tabs-font-color-selected: $grey-dark;
$menu-label-font-color: $grey-dark;
$card-item-description-font-color: $grey-medium;


//style border
%brd-grey-light {
  border: .1rem solid $grey-light;
}

// border-radius
$brd-rad-large : .4rem;
$brd-rad-medium : 0.5rem;
$brd-rad-small : 0.3rem;


// transition
$hoverTransition: all 0.2s ease;


// shadow
%box-shadow {
  box-shadow: 0px 0px 4px -2px;
  // box-shadow: 0 .2rem .2rem 0 rgba(0, 0, 0, 0.2), 0 .2rem 2rem 0 rgba(0, 0, 0, 0.19);
}

// sizing
$progress-box-height: 565px;
$progress-box-width: 300px;

$promotion-box-height: 565px;
$promotion-box-width: 889px;


// break points 
// https://github.com/angular/flex-layout/wiki/Responsive-API
$break-xxs: 350px;
$break-xs: 450px;
$break-xsm: 525px;
$break-sm: 959px;
$break-xmd: 1000px;
$break-md: 1280px;
$break-mdlg: 1400px;
$break-mdxlg: 1600px;
$break-lg: 1919px;

$breakpoint-cover-vimeo-width: 1621.33px;
$breakpoint-cover-vimeo-height: 912px;

// Mobile break points
$break-mobile-width-xs: 320px;
$break-mobile-height-xs: 667px;
$breakpoint-sender: 1500px;