@font-face {
  font-family: 'Smash';
  src: url('/assets/fonts/Smash.2.42.36.eot?9qskxk');
  src:
    url('/assets/fonts/Smash.2.42.36.eot?9qskxk#iefix') format('embedded-opentype'),
    url('/assets/fonts/Smash.2.42.36.ttf?9qskxk') format('truetype'),
    url('/assets/fonts/Smash.2.42.36.woff?9qskxk') format('woff'),
    url('/assets/fonts/Smash.2.42.36.svg?9qskxk#Smash') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Smash' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-smash_audio:before {
  content: "\e900";
}

.icon-smash_video:before {
  content: "\e901";
}

.icon-smash_back:before {
  content: "\41";
}

.icon-smash_dots:before {
  content: "\42";
}

.icon-smash_icologo_chip:before {
  content: "\43";
}

.icon-smash_remote:before {
  content: "\44";
}

.icon-smash_logo_no_chip:before {
  content: "\46";
}

.icon-smash_logo_no_icologo:before {
  content: "\47";
}

.icon-smash_profil:before {
  content: "\49";
}

.icon-smash_image:before {
  content: "\4a";
}

.icon-smash_menu:before {
  content: "\4e";
}

.icon-smash_trash:before {
  content: "\4f";
}

.icon-smash_videos:before {
  content: "\50";
}

.icon-smash_images:before {
  content: "\51";
}

.icon-smash_icologo_no_chip:before {
  content: "\52";
}

.icon-smash_logo_chip:before {
  content: "\53";
}

.icon-smash_check:before {
  content: "\54";
}

.icon-smash_download:before {
  content: "\55";
}

.icon-smash_expand_arrow:before {
  content: "\56";
}

.icon-smash_close_eye:before {
  content: "\57";
}

.icon-smash_open_eye:before {
  content: "\58";
}

.icon-smash_nopreview:before {
  content: "\63";
}

.icon-smash_copy:before {
  content: "\64";
}

.icon-smash_settings:before {
  content: "\65";
}

.icon-smash_fusee:before {
  content: "\e903";
}

.icon-smash_down:before {
  content: "\e904";
}

.icon-smash_back_arrow:before {
  content: "\e905";
}

.icon-smash_folder:before {
  content: "\e906";
}

.icon-smash_file:before {
  content: "\e907";
}

.icon-smash_arrow_navigation:before {
  content: "\e908";
}

.icon-smash_close:before {
  content: "\e909";
}

.icon-smash_edit:before {
  content: "\e90a";
}

.icon-smash_less:before {
  content: "\e90b";
}

.icon-smash_plus:before {
  content: "\e90c";
}

.icon-smash_informations:before {
  content: "\e90d";
}

.icon-smash_lock:before {
  content: "\e90e";
}

.icon-smash_facebook:before {
  content: "\ea90";
}

.icon-smash_instagram:before {
  content: "\ea92";
}

.icon-smash_twitter:before {
  content: "\ea96";
}

.icon-smash_youtube:before {
  content: "\ea9d";
}

.icon-smash_linkedin2:before {
  content: "\eaca";
}

.icon-smash_play:before {
  content: "\ea1c";
}