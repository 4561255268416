@import "variables";
@import "flex";
@import "size";


.main-title {
    @include flexbox;
    @include justify-content(center);
    font-size: 3rem;
    font-family: Nunito;
    font-weight: 900;
    width: 100%;
    margin: 2rem 0;
    line-height: 3rem;
}

.container-flex {
    display: flex;
    flex-direction: column;
    height: calc(100% - 7rem);
}


.mobile-container {
    @include flexbox;
    @include flex-direction(column);
    overflow: hidden;
    padding: 0 2rem;

    &.scroll {
        overflow-y: scroll;
        height: 100%;
        flex: 1;
    }

    &.grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.5rem 1rem;

        .item {
            @extend %flex-layout-column;
            @include align-items(center);
            @include justify-content(center);
            border-radius: 1.3rem;
            background-color: $bg-white;
            height: auto;
            width: 100%;
            padding: 2rem;

            .thumbnail {
                background-size: cover;
                border-radius: 50%;
                font-size: 4rem;
                width: 4rem;
                height: 4rem;
            }

            .label {
                font-family: "Roboto";
                line-height: 1.3rem;
                margin-top: 1rem;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                width: 13rem;
                overflow: hidden;
            }

            &.selected {
                border: $grey-dark 0.2rem solid;
            }
        }
    }

    .section-title {
        color: $grey-medium-light;
        font-weight: 900;
        font-size: 2rem;
        margin-bottom: 1.5rem;
        width: 100%;

        &:not(:first-child) {
            margin-top: 2rem;
        }
    }

    .input-container {
        padding-bottom: 1.2rem;
        width: 100%;

        ::ng-deep .ng-select-container {
            height: 6rem !important;
            border-color: transparent !important;
            border-radius: 1.3rem;
            font-size: 1.7rem;
            padding: 0 1rem;
        }

        ::ng-deep .ng-dropdown-panel {
            border-color: transparent !important;
        }

        ::ng-deep .ng-dropdown-panel.ng-select-top {
            border-top-right-radius: 1.3rem;
            border-top-left-radius: 1.3rem;
            overflow: hidden;
        }

        ::ng-deep .ng-option {
            padding: 1.2rem 2rem;
            font-size: 1.5rem;
        }
    }

    .form-footer {
        @include flexbox;
        @include justify-content(flex-end);
        @include align-items(center);
        @include flex-direction(row);
        width: 100%;
        padding-bottom: 2rem;
    }

    app-smash-input {
        ::ng-deep {
            .base-input-container {
                input {
                    font-size: 1.7rem !important;
                }
            }
        }
    }


    .btn-primary {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        height: 5rem;
        border-radius: 1.3rem;
        width: 100%;
        font-size: 1.7rem;
        // margin-bottom: 0.8rem;
        font-weight: 600;
        background-color: $main-primary-color;
        color: $bg-white;
        font-weight: 900;

        &:disabled {
            cursor: default;
            opacity: 0.5;
        }
    }
}

.top-nav-tabs {
    @extend %flex-layout-row;
    @include align-items(center);
    width: 100%;
    margin-bottom: 1.5rem;

    .tab {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        background-color: transparent;
        height: 5rem;
        width: 100%;
        font-size: 1.7rem;

        &-title {
            color: $grey-dark;
            font-weight: 700;
            text-align: left;
            width: 100%;
            padding-left: 2.4rem;
        }

        &.active {
            border-bottom: 0.2rem solid $grey-dark;
            color: $grey-dark;
            font-weight: 700;
        }
    }
}

.action-footer {
    position: static;
    @extend %flex-layout-column;
    @include justify-content(space-between);
    @include align-items(center);
    width: 100%;
    padding: 2rem 2rem;
    height: 14rem;
    border-top: solid 1px $grey-light;
    background-color: $grey-lighter;
}

// Account Mobile card
.mobile-card-title {
    color: $grey-medium-light;
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    width: 100%;
}

.mobile-card {
    @extend %flex-layout-column;
    @include align-items(center);
    @include justify-content(center);
    border-radius: 1.3rem;
    background: $light-bg-color;
    width: 100%;
    height: fit-content;
    margin-bottom: 2rem;
    overflow: hidden;
    position: relative;
    transition: 0.25s ease-in-out;
    left: 0rem;
    padding: 2rem;
    min-height: 10rem;

    .mobile-card-no-content-container {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);

        &-message {
            color: #888;
            font-weight: 400;
            font-size: 1.5rem;
            text-align: center;
        }

    }

    .details-line {
        padding-bottom: 1rem;
        width: 100%;
        line-height: 2.3rem;

        .title {
            @extend %flex-layout-row;
            @include justify-content(space-between);
            @include align-items(center);
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: $grey-dark;
            font-size: 1.7rem;
        }

        .description {
            @include flexbox;
            @include flex-direction(row);
            color: $grey;
            font-weight: 400;
            font-size: 1.5rem;
        }

        .link {
            color: $smash-blue;
            text-decoration: underline solid $smash-blue;
        }
    }
}

// overlay
.overlay,
.overlay-redirection {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include size(100vw, 100%);
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    transition: opacity ease-in 200ms;
    opacity: 0;
    height: 0;

    &.visible {
        height: 100%;
        opacity: 1;
    }
}

.text-redirection {
    color: $bg-white;
    font-size: 2.2rem;
    font-family: "Roboto";
}

.overlay-deleting {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include size(100vw, 100%);
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transition: opacity ease-in 200ms;
    opacity: 0;
    height: 100%;
    opacity: 1;
}