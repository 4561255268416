@import "variables";
@import "flex";
@import "icons";

@mixin premium-horizontal-table {
  width: 100%;
  border-collapse: initial;
  overflow: visible;
  font-size: 1.3rem;
  padding: 1rem 0;

  tr {
    height: 2.8rem;
  }

  th,
  td {
    text-align: left;
    border-collapse: initial;
    vertical-align: middle;

    &:first-child {
      text-align: left;
      padding-left: 1.5rem;
    }
  }

  th {
    margin-left: 0.5rem;
    width: 15%;
    color: $grey-dark;
    font-weight: 900;
  }

  td {
    span {
      margin: 0 auto;
    }

    color: $grey-dark;
  }

  tr:last-child th {
    border-bottom: none;
  }

  tr:last-child td {
    border-bottom: none;
  }
}

@mixin premium-table {
  width: 100%;
  border-collapse: initial;
  overflow: hidden;
  font-size: 1.3rem;
  text-align: left;

  th,
  td {
    &:first-child {
      text-align: left;
      padding: 1rem 0.5rem 1rem 2rem;
    }

    &:last-child {
      text-align: right;
      padding-right: 1.5rem;
    }
  }

  thead {
    vertical-align: middle;

    tr {
      background: $grey-lighter;

      th {
        cursor: pointer;
        border-bottom: 1px solid $grey-light;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-family: "Roboto", sans-serif;
        font-weight: 900;

        &.left {
          text-align: left;
        }

        .icon {
          font-size: 1rem;
        }
      }
    }
  }

  tbody {
    tr {
      vertical-align: middle;

      td {
        border-bottom: 1px solid $grey-light;
        text-overflow: ellipsis;

        span {
          color: $grey-dark;
          display: block;
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          font-size: 1.3rem;
        }
      }
    }

    tr:hover {
      background-color: #f7fafc;
    }
  }
}