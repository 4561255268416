@import "variables";

body {
    font-family: "Roboto", sans-serif;
    color: $grey-dark;
}

h1,
h2,
h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
}

h1 {
    font-size: 1.6rem;
}

b,
strong {
    font-weight: 600;
}

// pour respecter le fromsmash original basé sur bootstrap
body {
    line-height: 1.42857143;
    font-size: 1.4rem;
}

body.prepare {
    // block inertie scroll for safari
    overflow-x: hidden;
}

input,
textarea {
    font-size: 13px;
    line-height: 13px;
}

.error-message {
    color: $red;
}

.confirmation-message {
    color: $grey-dark;
}

.mobile-section-title {
    font-size: 1.7rem;
    font-family: "Nunito";
    font-weight: 900;
    margin-bottom: 1rem;
}

.mobile-section-description {
    font-size: 1.5rem;
    font-family: "Roboto";
}