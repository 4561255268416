@import 'variables';

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/*
 * Add a simple round spinner
 *
 * You should use it in pseudo element, see sample below
 */
@mixin add_spinner($size: 30px, $main-color: #ccc, $alt-color: #07d, $thickness: 1px) {
  content: "";
  box-sizing: border-box;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: $thickness solid $main-color;
  border-top-color: $alt-color;
  animation: spinner 0.6s linear infinite;
}

.loading-spinner-grey {
  @include add_spinner(3rem, $grey-dark, $grey-light, $thickness: .2rem);
}

%loading-spinner-grey {
  @include add_spinner(3rem, $grey-dark, $grey-light, $thickness: .2rem);
}

.button-loading-spinner {
  @include add_spinner(2rem, $light-bg-color, $grey-light, $thickness: .2rem);
}

.loading-spinner-white {
  @include add_spinner(3rem, $light-bg-color, $grey-light, $thickness: .2rem);
}

.loading-spinner-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}