@import "variables";
@import "flex";
@import "buttons";
@import "tables";

// use this structure to avoid the mixin
.premium-searchbar {
    height: 3rem;
    margin-bottom: 2.5rem;
}

.premium-card {
    @extend %flex-layout-column;
    border-radius: $brd-rad-medium;
    background: $light-bg-color;
    border-radius: $brd-rad-large;
    margin-bottom: 2rem;
    overflow: visible;
    border: 0.01rem solid $premium-grey-border-color;

    &-header {
        @extend %flex-layout-row;
        @include align-items(center);
        @include justify-content(space-between);
        padding: 1rem 2rem;
        background-color: $grey-lighter;
        min-height: 6rem;
        border-top-right-radius: 0.3rem;
        border-top-left-radius: 0.3rem;
        border-bottom: 0.1rem solid $premium-grey-border-color;

        &-title {
            flex-grow: 1;
            
            h2 {
                font-weight: 100;
                font-size: 1.2rem;
            }
        }

        &-actions {
            @include flexbox;
            @include justify-content(flex-end);
            @include align-items(center);
            height: 100%;
            flex-grow: 1;
            min-width: 20%;

            .icon {
                @include flexbox;
                @include align-items(center);
                font-size: 1.8rem;
                margin: 0 1rem;

                i {
                    height: 18px;
                    display: block;
                }
            }

            app-smash-input {
                min-width: 35rem;
            }

            button {
                height: 50%;
            }

            button:last-child {
                margin-left: 1rem;
            }
        }
    }

    &-tabs {
        @extend %smash-card-tabs;
        border-bottom: solid 1px $grey-light;
        margin-bottom: 2rem;

        .tab {
            cursor: pointer;
            line-height: 5rem;
            color: $grey-medium;
            font-weight: 400;
            margin-right: 1rem;

            &-selected {
                border-bottom: 0.2rem solid $grey-dark;
                color: $grey-dark;
                font-weight: 700;
            }
        }
    }

    .premium-card-loading-container {
        @include flexbox;
        @include align-items(center);
        margin: 0 auto;
        height: 25rem;
    }

    &-center {
        height: 100%;
        position: relative;

        &-container {
            padding: 2rem;
            @extend %flex-layout-column;
        }

        &-no-content {
            @extend %flex-layout-column;
            @include justify-content(center);
            @include align-items(center);
            min-height: 20rem;
            height: 100%;

            &-message {
                margin-bottom: 2rem;
                font-size: 1.5rem;
                font-weight: 900;
            }
        }

        .stats {
            @include flexbox;
            @include justify-content(center);
            @include align-items(stretch);
            @include align-content(stretch);
            height: 20rem;

            &-item {
                @include flexbox;
                @include flex-direction(column);
                @include justify-content(center);
                @include align-items(center);
                width: 100%;
                background: $bg-white;

                &:not(:first-child) {
                    border-left: 0.1rem solid $premium-grey-border-color;
                }

                .counter {
                    font-size: 4.5rem;
                }

                .subtitle {
                    font-size: 1.5rem;
                }
            }
        }

        .informations {
            @include premium-horizontal-table;
            padding-left: 0.5rem;

            .link-blue {
                color: $smash-blue;
                text-decoration: underline solid $smash-blue;
            }

            .expiration-text {
                color: $main-primary-color !important;
            }
        }

        .updating {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            left: 0;
            right: 0;
            background: rgb(0, 0, 0, 0.1);
        }

        .loading {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            min-height: 10rem;
        }
    }

    &-section {
        
        &-container {
            @extend %flex-layout-column;
            @include flex-direction(column);
            @include justify-content(center);
            @include align-items(center);
            padding: 2rem;
            margin: auto;
            width: 100%;
        }

        &-container:not(:last-child) {
            padding-bottom: 2rem;
            border-bottom: 0.1rem solid $premium-grey-border-color;
        }

        &-header {
            width: 100%;
            
            &-title {
                @extend %flex-layout-row;
                @include align-items(center);

                &-container {
                    @extend %flex-layout-row;
                    @include justify-content(space-between);
                    @include align-items(center);
                }

                font-size: 1.5rem;
                font-weight: 900;
                margin-right: 2rem;
            }

            &-subtitle {
                font-size: 1.2rem;
                font-weight: 400;
                margin-bottom: 1rem;
            }
        }

        &-content {
            width: 100%;
        }

    }

    &-footer {
        @extend %flex-layout-row;
        @include align-items(center);
        @include justify-content(flex-end);
        border-top: 0.1rem solid $premium-grey-border-color;
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        padding: 1rem 2rem;

        button {
            margin-left: 1rem;
        }
    }
}