@import "variables";

// %input {
//   width: 100%;
// }

// .input-small {
//   @extend %input;
//   height: 3rem;
// }

// .input-medium {
//   @extend %input;
//   height: 4rem;
// }

.input-error {
  color: $red;
  font-size: 1.3rem;
}