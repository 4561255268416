@import "variables";
@import "flex";
@import "buttons";
@import "loading-spinners";
@import "mixins";

$modal-border-radius: 0.5rem;
$modal-background-color: #fff;
$modal-shadow-background-color: rgba(0, 0, 0, 0.9);

.modal {
    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);
    height: auto;
    text-align: center;
    border-radius: $modal-border-radius;
    background: $bg-white;
    position: relative;
    overflow-y: visible;

    @include mobile {
        border-radius: 1.3rem;
    }

    .header {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(center);
        min-height: 6rem;
        padding: 2rem 4rem;

        h1 {
            @include mobile {
                font-family: Nunito;
                font-weight: 900;
                font-size: 1.6rem;
                line-height: 2.1rem;
                font-size: 2.1rem;
            }
        }

        .title {
            font-family: Nunito;
            font-weight: 900;
            font-size: 1.6rem;
            line-height: 2.1rem;

            @include mobile {
                font-size: 3rem;
            }
        }

        .title.title-big {
            font-family: Nunito;
            font-weight: 900;
            font-size: 2.1rem;
            line-height: 2.1rem;
        }

        .title.title-medium {
            font-family: Nunito;
            font-weight: 900;
            font-size: 1.6rem;
            line-height: 2.1rem;
        }

        .subtitle {
            @include mobile {
                font-size: 1.7rem;
            }
        }
    }

    .body {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(center);
        min-height: 5rem;
        overflow-y: visible;
        width: 100%;

        &.body-scroll {
            max-height: 52rem !important;
            overflow-y: scroll !important;
        }

        .section {
            width: 100%;
            padding: 2rem;

            .title-section {
                @include flexbox;
                align-items: left;
            }

            .description {
                @extend %flex-layout-center;
                text-align: center;

                @include mobile {
                    font-size: 1.7rem;
                }
            }

            .description.align-left {
                text-align: left;
            }

            .section-row {
                @extend %flex-layout-row;
                @include justify-content(space-between);
                @include align-items(center);
                position: relative;
            }

            .section-column {
                @extend %flex-layout-column;
                @include align-items(center);
                position: relative;
            }

            .form-section {
                margin-top: 1rem;

                .form-input {
                    position: relative;
                    margin-top: 1rem;
                }

                .form-footer {
                    margin-top: 1rem;
                    @extend %flex-layout-row;
                    @include justify-content(flex-end);
                }
            }
        }

        .section:not(:first-child) {
            padding: 1rem 2rem;
        }
    }

    .footer {
        @include flexbox;
        @include flex-direction(row);
        @include justify-content(flex-end);
        width: 100%;
        border-radius: 0 0 0.5rem 0.5rem;
        height: 6rem;
        padding: 2rem;
        background-color: $bg-white;
        align-items: center;
        border-style: solid none none none;
        box-shadow: inset 0 1px #e3e8ee;
        border-top: 0.1rem solid $premium-grey-border-color;

        @include mobile {
            height: auto;
            border-radius: 0 0 1.3rem 1.3rem;
            border-top: none;
            padding: 1rem 2rem 2rem 2rem;
            flex-direction: column-reverse;
            box-shadow: none;
        }

        button {
            margin-left: 1rem;

            @include mobile {
                @include flexbox;
                @include align-items(center);
                @include justify-content(center);
                height: 5rem;
                border-radius: 1.3rem;
                width: 100%;
                font-size: 1.7rem;
                font-weight: 600;
                margin-left: 0;
            }
        }

        .white {
            @include mobile {
                background-color: transparent;
                color: $grey-dark;
                font-weight: 900;
                border: none;
                height: 5rem;
                margin-top: 1rem;
            }
        }
    }
}

%smash-card {
    border-radius: $brd-rad-medium;
    background: $bg-white;
}