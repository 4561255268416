@import "variables";

.smash-logo {
  color: $main-primary-color;
  text-align: center;
  font-size: 6rem;
}

.smash-logo-white {
  color: $light-bg-color;
  text-align: center;
  font-size: 6rem;
}