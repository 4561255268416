@import "variables";
@import "flex";

.ng-select {

  // box-sizing: border-box;
  &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: unset;
    box-shadow: none;
  }

  &.basic {
    .ng-select-container {
      border-color: $grey-dark;
    }
  }

  &[invalid] {
    .ng-select-container {
      border-radius: 0.3rem;
      border: solid 1px $red;
    }

    .ng-value-container>.ng-placeholder {
      color: $red;
    }
  }

}