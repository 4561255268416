@import "variables";
@import "flex";
@import "buttons";

%smash-card {
  border-radius: $brd-rad-medium;
  background: $light-bg-color;
  overflow: hidden;
}

%smash-card-header {
  height: 4.8rem;
  font-size: 16px;
  font-weight: bold;
}

%smash-card-tabs {
  @include flexbox;
  height: 4.8rem;
  flex-grow: 1;
}

%smash-card-alert {
  @extend %smash-card;
  border-radius: $brd-rad-medium;
}

%smash-card-menu {
  @extend %smash-card;
  @extend %box-shadow;
  border-radius: $brd-rad-small;
}

%smash-modal-settings {
  @extend %smash-card;
  @include flexbox;
  @include flex-direction(column);
  padding: 2.5rem 2rem;
  max-height: 500px;
  width: 40rem;
  background-clip: padding-box;
  text-align: center;
  position: relative;

  .close-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 2rem;
    height: 2rem;
    @extend %cross-modal-close;
  }

  .modal-header {
    @extend %flex-layout-column;
    @include justify-content(center);
    @include align-items(center);
    margin: 1rem 0;
    width: 100%;

    h1 {
      font-size: 1.6rem;
      font-weight: 700;
      color: $black;
      width: 100%;
      padding-bottom: 1rem;
    }
  }

  .modal-form-input {
    margin-top: 2rem;
    position: relative;
  }

  .modal-form-error {
    color: $main-primary-color;
    font-size: 1.2rem;
  }

  .modal-footer {
    @extend %flex-layout-row;
    @include justify-content(flex-end);
    @include align-items(center);
    margin-top: 2rem;
  }

  .modal-loading {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    left: 0;
    background: grey;
    opacity: 0.5;
    justify-content: center;
  }
}

%smash-modal-info {
  @extend %smash-card;
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(space-around);
  @include align-items(center);
  padding: 2.5rem 2rem;
  position: relative;
  background-clip: padding-box;

  h1 {
    font-family: Nunito;
    font-weight: 900;
    font-size: 2.1rem;
    margin-bottom: 2.5rem;
    line-height: 2.1rem;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  p {
    font-size: 1.3rem;
    margin-bottom: 2rem;
    text-align: center;

    >a {
      transition: $hoverTransition;
      color: $grey;
      text-decoration: underline;

      &:hover {
        opacity: 0.65;
      }
    }
  }

  button {
    transition: $hoverTransition;

    &:hover {
      filter: brightness(80%);
    }
  }

  a.cta-link {
    cursor: pointer;
    margin-top: 2rem;
    color: $grey-dark;
    text-decoration: none;
    text-align: center;
    transition: $hoverTransition;

    &:hover {
      filter: brightness(80%);
    }
  }
}

@mixin premium-card {
  @extend %smash-card;
  @extend %flex-layout-column;
  border-radius: $brd-rad-large;
  background: $light-bg-color;
  margin: 1.5rem 0;
  overflow-x: hidden;
  overflow-y: visible;
  border: 0.01rem solid $premium-grey-border-color;
  margin-bottom: 2rem;
  min-width: 70rem;

  &-header {
    @extend %flex-layout-row;
    @include align-items(center);
    @include justify-content(space-between);
    padding: 1rem 2rem;
    background-color: #e3e8ee;
    min-height: 6rem;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    border-bottom: 0.1rem solid $premium-grey-border-color;

    &-title {
      flex-grow: 1;
      width: 100%;

      h2 {
        font-weight: 100;
      }
    }

    &-actions {
      @include flexbox;
      @include justify-content(flex-end);
      @include align-items(center);
      height: 100%;
      flex-grow: 1;
      width: 100%;
      min-width: 20rem;

      .icon {
        @include flexbox;
        @include align-items(center);
        font-size: 1.8rem;
        margin: 0 1rem;

        i {
          height: 18px;
          display: block;
        }
      }

      button {
        height: 50%;
      }

      button:last-child {
        margin-left: 1rem;
      }
    }
  }

  &-center {
    height: 100%;
    position: relative;

    &-no-content {
      @extend %flex-layout-column;
      @include justify-content(center);
      @include align-items(center);
      min-height: 20rem;
      height: 100%;

      &-message {
        margin-bottom: 2rem;
        font-size: 1.5rem;
        font-weight: 900;
      }
    }
  }

  &-footer {
    @extend %flex-layout-row;
    @include align-items(center);
    @include justify-content(flex-end);
    border-top: 0.1rem solid $premium-grey-border-color;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding: 1rem 2rem;

    button {
      margin-left: 1rem;
    }

    .buttons-container-right {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

// Upoloader Mobile card 
.mobile-card {
  @extend %flex-layout-column;
  @include align-items(center);
  @include justify-content(center);
  border-radius: 1.3rem;
  background: $light-bg-color;
  width: 100%;
  height: fit-content;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
  transition: 0.25s ease-in-out;
  left: 0rem;
  padding: 2rem;
  min-height: 10rem;

  .mobile-card-no-content-container {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);

    &-message {
      color: #888;
      font-weight: 400;
      font-size: 1.5rem;
      text-align: center;
    }

  }
}