/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html {
  // overflow-y: overlay;
  box-sizing: border-box;
  height: 100%;
}

// this cause a  bug safari
*::before,
*::after,
* {
  box-sizing: inherit;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none;
  word-break: break-word;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  height: 100%;
  width: 100%;
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

// ajout johann
//bordure sur les select (bouton, formulaires )
*:focus {
  outline: none !important;
}

// dimensionnement en rem
html {
  font-size: 62.5%;
}

::placeholder {
  opacity: 0.5;
  // padding: 2px 0px;
}

input,
textarea {
  font-family: "Roboto", sans-serif;
}

// Remove arrows from input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// /* width */
// body ::-webkit-scrollbar {
//   width: 5px;
// }

// /* Track */
// body ::-webkit-scrollbar-track {
//   background: $bg-white;
// }

// /* Handle */
// body ::-webkit-scrollbar-thumb {
//   background:$grey-light;
//   border-radius: 30px;
// }

// /* Handle on hover */
// body ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

/* Edge and IE remove eye on password because it is already implemented from our side */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

div,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}