@import "variables";
@import "flex";
@import "mixins";
@import "buttons";
@import "size";
@import "input";
@import "loading-spinners";

$hoverTransition: all 0.5s ease;

:host {
  z-index: 10000;
}

.modal-container {
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 34, 0.788);
  @include flexbox;
  flex-direction: column;
  @include align-items(center);
  @include justify-content(center);

  &-body {
    z-index: 1200;
    height: 100%;
    width: 100%;
    height: 90vh;
    width: 90vw;
    @include align-items(center);
    @include flexbox;
    @include justify-content(center);
  }
}

.modal-container.fullscreen {
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
}

.design-container {
  @extend %flex-layout-row;
  height: 100%;
  width: 100%;
  background: $bg-white;
  border-radius: 3px;

  .design-left-box {
    @include flexbox;
    flex-direction: column;
    max-width: 32rem;
    padding: 15px;
    overflow: hidden;

    @include for-tablet-portrait-down {
      width: 100%;
      max-width: none;
    }

    &-header {
      height: 40px;
      line-height: 40px;
      position: relative;
      @include flexbox;
      @include justify-content(space-between);

      &-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: inherit;
      }

      &-save {
        &-button {
          cursor: pointer;

          &:first-child {
            font-weight: 600;
            margin-right: 10px;
          }

          &:hover {
            color: $grey-light;
          }

          &-replay {
            cursor: pointer;
            position: absolute;
            right: 0;
          }
        }
      }

      &-back {
        cursor: pointer;
        @include flexbox;
        width: 80px;
        @include justify-content(space-around);
        @include align-items(center);

        .back-icon {
          position: absolute;
          left: 0;
          line-height: initial;
          transition: $hoverTransition;
        }

        &:hover .back-icon {
          left: -5px;
        }
      }

      &-button {
        font-weight: 700;
        padding-right: 10px;
        outline: 0;
        transition: $hoverTransition;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        &:last-child {
          font-weight: 400;
        }
      }
    }

    &-selection>button {
      @include important-label($main-dark-color, $grey-light);
      width: 100%;
      height: 47px;
      cursor: pointer;
    }

    &-list {
      &-container {
        position: relative;
        cursor: pointer;
        height: 44rem;
        max-height: 44rem;
      }

      &-content {
        &-item {
          border-radius: $brd-rad-small;

          background-color: $grey-lighter;
          display: flex;
          justify-content: space-between;
          @include align-items(center);
          height: 45px;
          cursor: pointer;
          width: 100%;
          margin: 1rem 0 1rem 0;

          &.enabled {
            @include important-label();
          }

          &.disabled {
            @include important-label(red);
          }

          >div {
            height: 100%;
            display: flex;
            @include align-items(center);
            margin-left: 10px;
            width: 100%;
          }

          label {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            margin: 0 13px;

            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            min-height: 2rem;
            // margin-left: 5rem;

            .replay-icon {
              background: transparent;

              &:hover {
                background: rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
              }
            }
          }

          &-icon {
            margin-right: 10px;
          }
        }
      }
    }

    &-edition {
      &-container {
        position: relative;
        cursor: pointer;
      }

      &-content {
        &-item {
          border-radius: $brd-rad-small;

          background-color: $grey-lighter;
          display: flex;
          justify-content: space-between;
          @include align-items(center);
          height: 45px;
          cursor: pointer;
          width: 100%;
          top: 0;
          position: absolute;

          &.enabled {
            @include important-label();
          }

          &.disabled {
            @include important-label(red);
          }

          >div {
            height: 100%;
            display: flex;
            @include align-items(center);
            margin-left: 10px;
            width: 100%;
          }

          label {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            margin: 0 13px;

            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            min-height: 2rem;
            margin-left: 5rem;

            .replay-icon {
              background: transparent;

              &:hover {
                background: rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
              }
            }
          }

          &-icon {
            margin-right: 10px;
          }
        }
      }
    }

    &-text-content {
      margin: 40px 0 30px;
      word-break: break-all;

      h1 {
        font-weight: 700;
        margin-bottom: 15px;
      }
    }

    &-button-group {
      @extend %smash-button-white;
      width: 100%;
      margin-bottom: 15px;
      @include flexbox;
      @include align-items(center);

      button {
        background: $bg-white;
        width: 50%;
        height: 100%;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 0.1em;
        transition: $hoverTransition;
        cursor: pointer;

        &:first-child {
          border-right: 1px solid $grey-light;
        }

        &:hover,
        &.selected {
          background-color: $grey-lighter;
        }
      }
    }

    &-thumbnails {
      &-container {
        position: relative;
        overflow: auto;
        flex-grow: 2;
        overflow-y: overlay;
      }

      &-list {
        @include n-columns(80px, 15px, true, 3);
        opacity: 0;
        transform: scale3d(0, 0, 0);
        height: 0;
        transition: opacity 0.3s ease-out;

        &.displayed {
          transform: scale3d(1, 1, 1);
          height: 100%;
          opacity: 1;
          position: absolute;
          top: 0;
          height: auto;
        }

        &-item {
          border-radius: $brd-rad-small;
          display: block;
          position: relative;
          background-size: cover;
          background-position: center;
          background-color: rgba(0, 0, 0, 0);

          min-width: 80px;
          max-width: 80px;
          min-height: 80px;
          max-height: 80px;
          padding: 5px;
          transition: $hoverTransition;
          cursor: pointer;

          &:first-child {
            @extend %brd-grey-light;
            background-color: $grey-lighter;

            &:hover {
              border: solid 1px $grey-dark;

              span {
                color: $grey-dark;
              }
            }
          }

          &:hover .design-left-box-thumbnails-list-item-overlay {
            background-color: rgba(0, 0, 0, 0.5);
          }

          &:hover .design-left-box-thumbnails-list-item-overlay-delete {
            opacity: 1;
          }

          &.selected .design-left-box-thumbnails-list-item-overlay {
            background-color: rgba(0, 0, 0, 0.5);
          }

          &-content {
            color: $grey-light;
            position: relative;
            height: 100%;
            font-size: 1.2rem;
            text-align: center;
            @include flexbox;
            @include align-items(center);

            span {
              transition: $hoverTransition;
            }

            &-media-icon {
              text-align: left;
              color: $bg-white;
              position: absolute;
              bottom: 0;
              font-size: 20px;
            }
          }

          &-overlay {
            border-radius: $brd-rad-small;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            // opacity: 0;
            transition: $hoverTransition;
            background-color: rgba(0, 0, 0, 0);
            overflow: hidden;
            @include flexbox;
            @include align-items(center);

            &-checked {
              @include flexbox;
              @include justify-content(center);
              width: 100%;
              color: $bg-white;
              font-size: 2.5rem;
            }

            &-delete {
              z-index: 3000;
              opacity: 0;
              position: absolute;
              top: 0.5rem;
              right: 0.5rem;
              height: 15px;
              width: 15px;
              color: #ccc;
              font-size: 13px;
              outline: 0;
              transition-timing-function: ease;
              transition-duration: 0.2s;
            }

            &-media-icon {
              text-align: left;
              color: $bg-white;
              position: absolute;
              bottom: 0;
              left: 0.5rem;
              font-size: 20px;
            }
          }
        }
      }
    }

    &-config-form {
      >span {
        text-transform: uppercase;
        font-weight: 700;
      }

      &-medias {
        margin: 5px 0 20px;

        &-thumbnails-list {
          @include n-columns(80px, 18px, true, 3);

          .item-loading {
            background: rgba(0, 0, 0, 0.8);
          }

          &-item {
            border-radius: $brd-rad-small;
            display: block;
            position: relative;
            background-size: cover;
            background-position: center;
            min-width: 80px;
            max-width: 80px;
            height: 80px;
            padding: 5px;
            transition: $hoverTransition;
            cursor: pointer;

            &:first-child {
              @extend %brd-grey-light;
              background-color: $grey-lighter;

              &:hover {
                border: solid 1px $grey-dark;

                span {
                  color: $grey-dark;
                }
              }
            }

            &:hover .design-left-box-config-form-medias-thumbnails-list-item-overlay {
              opacity: 0.5;
            }

            &-content {
              color: $grey-light;
              position: relative;
              height: 100%;
              font-size: 1.2rem;
              text-align: center;
              @include flexbox;
              @include align-items(center);

              span {
                transition: $hoverTransition;
              }

              &-media-icon {
                text-align: left;
                color: $bg-white;
                position: absolute;
                bottom: 0;
                font-size: 20px;
              }
            }

            &-overlay {
              border-radius: $brd-rad-small;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              opacity: 0;
              transition: $hoverTransition;
              background-color: rgba(0, 0, 0, 0.5);
              overflow: hidden;
              @include flexbox;
              @include align-items(center);

              &-delete {
                position: absolute;
                top: 2px;
                right: 2px;
                color: $bg-white;
                font-size: 1.5rem;
              }
            }
          }

          .empty {
            border: solid 1px $red;
          }
        }
      }

      &-input {
        margin: 1rem 0 1.5rem;
      }

      &-select {
        width: 100%;
        margin: 0.5rem 0 1.5rem;
      }
    }
  }

  .design-right-box {
    @include for-tablet-portrait-down {
      display: none;
    }

    position: relative;
    width: 100%;
    // padding: 8px;
    overflow: hidden;
    border-left: 1px solid #ccc;
    @include flexbox;
    flex-direction: column;
    @include justify-content(center);
    align-items: center;
    background: $grey-light;

    &-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 200;
      padding: 8px;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
    }

    &-preview {
      width: 100%;
      position: relative;
      @include flexbox;
      flex-direction: column;
      @include justify-content(center);
      background-size: cover;
      background-repeat: no-repeat;

      &-media {
        position: inherit;
      }

      &-logo-container {
        width: 100%;
        @include flexbox;
        @include justify-content(center);
        position: relative;
        z-index: 100;
      }

      &-logo {
        ::ng-deep {
          app-smash-spinner {
            .tint-spinner-wrapper {
              width: 80%;
              height: 80%;
            }
          }
        }
      }

      &-container {
        width: 100%;
      }

      &-wrapper-container {
        position: absolute;
        bottom: -90px;

        ::ng-deep {
          app-smash-tooltip {
            .smash-tooltip {
              color: $grey-dark;
              font-size: 1.1rem;
              padding: 15px 10px;

              p {
                line-height: 1.8rem;

                &:first-child {
                  margin-bottom: 2%;
                }

                &:nth-child(2) {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }

      &-cover-countdown {
        border-radius: $brd-rad-small;
        background: $bg-white;
        height: 35px;
        align-self: center;
        position: absolute;
        bottom: -6px;
        color: $grey-dark;
      }
    }

    &-content {
      background: $main-dark-color;
      width: 80.8rem;
      height: 52rem;

      @media (max-width: $break-mdlg) {
        width: 68rem;
        height: 45rem;
      }

      @media (max-width: $break-md) {
        width: 60rem;
        height: 40rem;
      }

      @media (max-width: $break-sm) {
        width: 55rem;
        height: 40rem;
      }

      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      padding: 2rem;
      border-radius: 5px;
      background: $main-light-color;
    }
  }
}

.overlay {
  z-index: 1000;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(25, 25, 34, 0.788);
  display: flex;
  @include justify-content(center);
  @include align-items(center);
}

// Modal
.inner-modal {
  z-index: 10000;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(25, 25, 34, 0.788);
  @include flexbox;
  flex-direction: column;
  @include align-items(center);
  @include justify-content(center);

  .modal-body {
    z-index: 12000;
    height: 100%;
    width: 100%;
    @include align-items(center);
    @include flexbox;
    @include justify-content(center);
  }

  &-info-add-image-video {
    &-container {
      @extend %smash-modal-settings;
      @extend %flex-layout-column;
      @include justify-content(flex-end);
      text-align: center;
      height: 100%;
      height: 24rem;
    }

    &-header {
      @extend %flex-layout-column;
      @include justify-content(center);
      @include align-items(center);
      width: 100%;
      height: 50px;
    }

    &-title {
      font-size: 16px;
      font-weight: 700;
      color: $black;
      width: 100%;
    }

    &-body {
      @extend %flex-layout-column;
      @include justify-content(space-between);
    }

    &-body-container-main {
      @extend %flex-layout-row;
      @include justify-content(space-between);
      padding: 0 60px 40px 60px;
    }

    &-main-content {
      @extend %flex-layout-column;
      @include justify-content(flex-end);
      text-align: center;
      color: #999;
      position: relative;
      cursor: pointer;
      transition: all 0.15s ease-out;

      &:hover {
        color: $black;
      }
    }

    &-image-input {
      position: absolute;
      top: -3rem;
      left: -1rem;
      opacity: 0;
      width: 4.5rem;
      height: 10rem;
      cursor: pointer;
      z-index: 1;
    }

    &-icon {
      font-size: 40px;
    }

    &-body-container-video {
      @extend %flex-layout-column;
      @include justify-content(space-between);
      @include align-items(center);
      width: 100%;
      height: 85px;
    }

    &-description {
      font-size: 13px;
      color: $grey-dark;
      margin: 1rem 0;
    }

    &-text {
      font-size: 12px;
    }

    &-footer {
      @extend %flex-layout-row;
      background-color: $bg-white;
      @include justify-content(space-between);
      @include align-items(center);
      border-style: solid none none none;
      height: 50px;
      padding: 0 50px;
      height: 80px;
    }

    &-button {
      margin: 0 3px;

      &-save {
        @extend %smash-button-dark;
      }

      &-cancel {
        @extend %smash-button-white;
      }
    }
  }
}

.modal-info-close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  @extend %cross-modal-close;
}