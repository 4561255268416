@import "variables";
@import "flex";
@import "size";

// used by fake-modals
@mixin important-label($bg-color: $secondary-color03, $color: $grey-dark) {
  border-radius: $brd-rad-small;
  border: 0.1rem solid $bg-color;
  color: $color;
  background: lighten($bg-color, 25%);
}

@mixin light-shadow {
  // very light shadow for white background 
  box-shadow: 0 16px 65px rgb(168 181 205/7%),
    0 10.3704px 38.0671px rgba(168, 181, 205, .053),
    0 6.163px 20.7037px rgba(168, 181, 205, .043),
    0 3.2px 10.5625px rgba(168, 181, 205, .035),
    0 1.3037px 5.2963px rgba(168, 181, 205, .028),
    0 0.2963px 2.5579px rgba(168, 181, 205, .017);
}

@mixin for-tablet-portrait-down {
  @media (max-width: 899px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    @content;
  }
}