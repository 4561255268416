// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------
@use "sass:math";

@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse

@mixin flex-direction($value: row) {
  @if $value==row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  }

  @else if $value==column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  }

  @else if $value==column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  }

  @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }

  -webkit-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

// Flex Wrap
// - applies to: flex containers
// nowrap(default value) | wrap | nowrap

@mixin flex-wrap($value: nowrap) {
  // No Webkit/FF Box fallback.
  -webkit-flex-wrap: $value;

  @if $value==nowrap {
    -ms-flex-wrap: none;
  }

  @else {
    -ms-flex-wrap: $value;
  }

  flex-wrap: $value;
}

// Flex Direction and Wrap
// - applies to: flex containers
// row (default value) | nowrap

@mixin flex-flow($values: (row nowrap,
  )) {
  // No Webkit/FF Box fallback.
  -webkit-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

// Display Order
// - applies to: flex items
// <integer>

@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -moz-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

// Flex grow factor
// - applies to: flex items
// <number>

@mixin flex-grow($int: 1) {
  -webkit-box-flex: $int;
  -moz-box-flex: $int;
  -webkit-flex-grow: $int;
  -ms-flex: $int;
  flex-grow: $int;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>

@mixin flex-shrink($int: 0) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex: $int;
  flex-shrink: $int;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex items initial main size of the flex item
// <width>
@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  flex-basis: $value;
}

@mixin flex($fg: 1, $fs: 0, $fb: auto) {
  // Définir une variable pour l'utiliser
  // avec les propriétés box-flex
  $fg-boxflex: $fg;

  // Box-Flex ne prend qu'une valeur, on prend donc
  // la première valeur de la liste et on la renvoie.
  @if type-of($fg)=="list" {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box: $fg-boxflex;
  -moz-box: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around

@mixin justify-content($value: flex-start) {
  @if $value==flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  }

  @else if $value==flex-end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
  }

  @else if $value==space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  }

  @else if $value==space-around {
    -ms-flex-pack: distribute;
  }

  @else {
    -webkit-box-pack: $value;
    -moz-box-pack: $value;
    -ms-flex-pack: $value;
  }

  -webkit-justify-content: $value;
  justify-content: $value;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch

@mixin align-items($value: stretch) {
  @if $value==flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  }

  @else if $value==flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  }

  @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }

  -webkit-align-items: $value;
  align-items: $value;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch

@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;

  @if $value==flex-start {
    -ms-flex-item-align: start;
  }

  @else if $value==flex-end {
    -ms-flex-item-align: end;
  }

  @else {
    -ms-flex-item-align: $value;
  }

  align-self: $value;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch

@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;

  @if $value==flex-start {
    -ms-flex-line-pack: start;
  }

  @else if $value==flex-end {
    -ms-flex-line-pack: end;
  }

  @else {
    -ms-flex-line-pack: $value;
  }

  align-items: $value;
}

@mixin n-columns($min-width, $gutter, $last-equal: false, $max-cols: 5) {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter;
  margin-top: -$gutter;

  &-item {
    flex: 1 0 $min-width;
    margin-left: $gutter;
    margin-top: $gutter;

    @if $last-equal {
      @for $i from 2 through $max-cols {
        $screen-width: (
          $min-width * $i)+ ($gutter * $i
        );
      $column-width: math.div(100%, $i);

      @media (min-width: $screen-width) {
        max-width: calc(#{$column-width} - #{$gutter});
      }
    }

    $column-width: math.div(100%, $max-cols);

    @media (min-width: $min-width * $max-cols) {
      min-width: calc(#{$column-width} - #{$gutter});
    }
  }
}
}

//flex
%flex-layout-row {
  @include flexbox;
  @include flex-direction(row);
}

%flex-layout-row-center {
  @include flexbox;
  @include flex-direction(row);
  @include align-content(center);
  @include justify-content(center);
}

%flex-layout-column {
  @include flexbox;
  @include flex-direction(column);
}

%flex-layout-column-center {
  @include flexbox;
  @include flex-direction(column);
  @include align-content(center);
  @include justify-content(center);
}

%flex-layout-center {
  @include flexbox;
  @include justify-content(center);
}

%flex-layout-vh-center {
  @include flexbox;
  @include align-content(center);
  @include justify-content(center);
}

%flex-layout-justify {
  @include flexbox;
  @include justify-content(space-between);
}

%flex-layout-flex-end {
  @include flexbox;
  @include justify-content(flex-end);
}

.full-size-container {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  height: 100%;
  width: 100%;
}