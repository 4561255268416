@import "variables";
@import "flex";
@import "buttons";
@import "input";

.container-form {
  padding: 2rem;

  &-input {
    border-radius: $brd-rad-medium;
    width: 100%;
    margin-bottom: 1.3rem;

    &.extension {
      @include flexbox;
      @include align-items(center);
      background: $grey-light;

      app-smash-input {
        flex-grow: 2;
      }

      .container-form-input-extension {
        padding: 0rem 1rem;
      }
    }
  }

  &-select-container {
    height: 3.5rem;
    margin-bottom: 2rem;

    ::ng-deep .ng-select-container {
      height: 3.5rem !important;
      border-radius: 0.3rem !important;
    }

    ::ng-deep .scroll-host {
      max-height: 21rem;
    }
  }

  &-buttons {
    margin-left: 1rem;
  }
}

.premium-form-content-inline {
  padding: 0 2rem;

  .premium-form-section {
    @extend %flex-layout-row;
    padding: 1rem 0;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid $grey-light;
    }

    &-title {
      align-items: flex-start;
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 51%;
      color: $grey-dark;
      font-size: 1.4rem;
      position: relative;

      .description {
        color: $grey-light;
      }

      .input-message {
        right: 0;
        position: absolute;
        color: $grey-light;
      }

      .thumbnail {
        width: 14rem;
        margin-top: 1rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
    }

    &-content {
      @extend %flex-layout-row;
      @include align-items(flex-end);
      @include justify-content(flex-end);
      width: 49%;
      margin-left: 1rem;

      .btn.no-padding {
        padding: 0;
      }

      .container-select {
        width: 100%;
      }

      .input-param {
        margin-bottom: 0rem;

        app-smash-input-message {
          width: 100%;
        }
      }
    }
  }
}